import React from "react";
import * as Survey from "survey-react";
import {ReactComponent as Logo} from './body.svg';

export class BodyQuestionModel extends Survey.Question {
  getType() {
    return "bodyquestion";
  }

  get text() {
    return this.getPropertyValue("text", "");
  }
  set text(newValue) {
    this.setPropertyValue("text", newValue);
  }
}

export class BodyQuestion extends Survey.SurveyElementBase {
constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    // 「他の痛いところがありますか」の画面から戻る対応
    if (!window.fromReturnBtnFlag) {
      this.question.value = window.sessionStorage.getItem("bodyDatas");
    }
  }
  get question() {
    return this.props.question;
  }

  componentDidMount() {
    if (!window.fromReturnBtnFlag) {
      // body color
      var bodyIds = [];
      var pervIds = window.sessionStorage.getItem("bodyIds") || '[]';
      if (pervIds !== null && pervIds !== '[]') {
        bodyIds = pervIds.split(",");
        for (var i=0; i<bodyIds.length; i++) {
          document.getElementById(bodyIds[i]).style.fill = '#FFE869';
          document.getElementById(bodyIds[i]).style.fillOpacity = 'unset';
        }
      }
    }
  }

  handleClick(e) {
    var id = e.target.getAttribute("id");
    if (!id.includes("path") && !id.includes("svg")) {
      var questionValue = unicodeUnescape(id);
      console.log(questionValue);
      if (questionValue !== null) {
        // body data
        var bodyDatas = [];
        var pervDatas = window.sessionStorage.getItem("bodyDatas") || '[]';
        if (pervDatas !== null && pervDatas !== '[]') {
          bodyDatas = pervDatas.split(",");
        }
        // body color
        var bodyIds = [];
        var pervIds = window.sessionStorage.getItem("bodyIds") || '[]';
        if (pervIds !== null && pervIds !== '[]') {
          bodyIds = pervIds.split(",");
        }

        var dataIndex = bodyDatas.indexOf(questionValue);
        var target = document.getElementById(id);
        if (dataIndex < 0) {
          bodyDatas.pop();
          var oldId = bodyIds.pop();
          if (oldId != null) {
            var oldTarget = document.getElementById(oldId);
            oldTarget.style.fill = '#808080';
            oldTarget.style.fillOpacity = '0.0164319';
          }

          bodyDatas.push(questionValue);
          target.style.fill = '#FFE869';
          target.style.fillOpacity = 'unset';
          bodyIds.push(id);
        } else {
          bodyDatas.splice(dataIndex, 1);
          target.style.fill = '#808080';
          target.style.fillOpacity = '0.0164319';
          var idIndex = bodyDatas.indexOf(id);
          bodyIds.splice(idIndex, 1);
        }
        window.sessionStorage.setItem("bodyDatas", bodyDatas);
        window.sessionStorage.setItem("bodyIds", bodyIds);
        document.getElementById("bodyQqestionValue").value = bodyDatas.toString();
        this.question.value = bodyDatas.toString();
      }   
    }
  }

  // TODO サイズ指定は別途決める。
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;
    // 初期値設定
    var defaultValue = this.question.value === null ? "" : this.question.value;
    return (
      <div style={{textAlign: 'center'}} className={cssClasses.root}>
        <Logo width="60%" height="60%" onClick={this.handleClick} />
        <input type="text" id="bodyQqestionValue" disabled="disabled" value={defaultValue} />
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "bodyquestion",
  [
    { name: "text" }
  ],
  function() {
    return new BodyQuestionModel("");
  },
  "question"
);


Survey.ReactQuestionFactory.Instance.registerQuestion("bodyquestion", props => {
  return React.createElement(BodyQuestion, props);
});

var unicodeUnescape = function(str) {
  var result = '';
  if (str !== null) {
    var strs = '';
    strs = str.match(/\\u.{4}/ig);
    if (!strs) return str;
    for (var i = 0, len = strs.length; i < len; i++) {
        result += String.fromCharCode(strs[i].replace('\\u', '0x'));
    }
  }
  return result;
};
