import React , { Component } from "react";
import "./App.css";
// eslint-disable-next-line
import { BodyPage } from "./BodyQuestion";
// eslint-disable-next-line
import { BodyMultiPage, BodyQuestionMultipleModel } from "./BodyQuestionMultiple";
// eslint-disable-next-line 
import { JsonImportPage } from "./JsonImportQuestion";

import "bootstrap/dist/css/bootstrap.css";
import * as Survey from "survey-react";
import "survey-core/survey.i18n"
import "survey-react/survey.css";
import "survey-react/modern.css";
import { interview } from "./interview.js";
import { interview2 } from "./interview2.js";
import showdown from "showdown";

import isElectron from "is-electron";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";


export default class App extends Component {
    constructor (props) {
        super(props);
        this.state = {
            model: new Survey.Model(interview),
            completeCount: 0,
            language: ""
        };
        
        // thisを参照できるようにする。
        this.onCurrentPageChanged = this.onCurrentPageChanged.bind(this);
        this.onAfterRenderPage = this.onAfterRenderPage.bind(this);
        this.onStarted = this.onStarted.bind(this);
        this.onComplete = this.onComplete.bind(this);
    }

    componentDidMount () {
        // 痛いところがありますかのページ対応
        window.fromReturnBtnFlag = false
        window.onbeforeunload = function () {
            window.sessionStorage.removeItem("dataBackups");
        }
    }

    //Define Survey JSON
    //Here is the simplest Survey with one text question
    json = interview;
    json2 = interview2;

    onCurrentPageChanged(survey) {
    
//         console.log(survey.data);
//         if(window.fromReturnBtnFlag) {
//             let prevDatas = window.sessionStorage.getItem("dataBackups") || "[]";
//             let datas = JSON.parse(prevDatas);
//             datas.push(this.state.model.data);
//             window.sessionStorage.setItem("dataBackups", JSON.stringify(datas));
//             document.getElementById("bodyQqestionValue").value = "";
//             this.state.model.clear();
//             window.fromReturnBtnFlag = false;
//         }
    }

    onValueChanged(survey, options) {
        // 表紙の言語切替による表示変更
        if (options.name === "language") {
            survey.localeValue = options.value;
            survey.render();
        }
    }

    onAfterRenderSurvey(survey) {
        // 問診開始時のイベント。前回の問診情報をクリアする。
        window.sessionStorage.removeItem("importedValue");
        window.sessionStorage.removeItem("dataBackups");
    }

    onAfterRenderPage(survey) {
        survey.locale = this.state.language;
        //console.log(survey.data);
        if (this.state.completeCount === 1 && Object.keys(survey.data).length === 0) {
            // interview2.js用の回答データがあれば、画面に反映する。
            let importedValue = window.sessionStorage.getItem("importedValue");
            if (importedValue) {
                let inputJson = JSON.parse(importedValue);
                let answer = {};

                // 数値を選択項目値に変換する。
                for (let name of ["state-flexion", "state-extension","state-rotation"]) {
                    if (inputJson.hasOwnProperty(name)) {
                        // 部分回答jsonの場合、jsonを組み立てる。
                        let map = inputJson[name];
                        Object.entries(map).forEach(([key, value], index) => {
                            survey.activePage.elementsValue.forEach((matrix) => {
                                if (matrix.name === name) {
                                    matrix.columns.forEach((col) => {
                                        if (col.name === key) {
                                            col.choices.forEach((choice) => {
                                                let text = "(" + value + ")";
                                                if (choice.value.includes(text)) {
                                                    map[key] = choice.value;
                                                } 
                                            });
                                        }
                                    });
                                }
                            });
                        });
                        answer[name] = [map];
                    }
                }

                if (inputJson.hasOwnProperty("groupB")) {
                    // 通常の回答jsonの場合、そのままセットする。
                    answer = inputJson.groupB;
                }
                
                if (answer) {
                    //console.log(JSON.stringify(answer));
                    survey.data = answer;
                }
            }
        }
    }

    onStarted(survey) {
        // 表紙から開始ボタンを押したときのイベント
        let importedValue = window.sessionStorage.getItem("importedValue");
        if (importedValue) {
            let inputJson = JSON.parse(importedValue);

            let q1Vals = [];
            // 前バージョンフォーマットを現バージョンに戻す。
            for (let i in inputJson.groupA) {
                let item = inputJson.groupA[i];
                if ("language" in item) {
                    continue;
                }
                let q1Val = item["1"];
                let q2Val = item["2"];
                let qDousaVal = item["dousa"];

                let q2Key = q1Val + "-2";
                let qDousaKey = q1Val + "-dousa";
                
                q1Vals.push(q1Val);
                survey.setValue(q2Key, q2Val);
                survey.setValue(qDousaKey, qDousaVal);
            }
            survey.setValue("1", q1Vals.join(","));
        }
        
        // 言語切り替え
        let lang = survey.data.language;
        survey.locale = lang;
        this.setState({language: lang});
    }


    //Define a callback methods on survey complete
    onComplete(survey) {
        if (this.state.completeCount === 0) {
            //Get input datas from session storage
            var datas = [];
            var prevDatas = window.sessionStorage.getItem("dataBackups") || "[]";
            datas = JSON.parse(prevDatas);
            datas.push(survey.data);
            window.sessionStorage.setItem("dataBackups", JSON.stringify(datas));
            this.json2.locale = survey.data.language;
            this.setState({
                model: new Survey.Model(this.json2)
            });
            this.setState({
                completeCount: this.state.completeCount + 1
            });

        } else {
            // 前バージョンのjsonフォーマットに変換する。
            var jsonStr = window.sessionStorage.getItem("dataBackups");
            var jsonObj = JSON.parse(jsonStr);
            var answer1 = jsonObj[0];
            var newData = [];

            var keys = Object.keys(answer1);
            for (let i in keys) {
                let key = keys[i];
                if ("1" === key) {

                    let parts = [];
                    if (answer1[key].indexOf(",") >= 0) {
                        parts = JSON.stringify(answer1[key]).split("\"").join("").split(",");
                    } else {
                        parts.push(answer1[key]);
                    }
                    for (let part in parts) {
                        let item = {};
                        item[key] = parts[part];
                        newData.push(item);
                    }

                } else {
                    let added = false;
                    for (let j in newData) {
                        let data = newData[j];
                        if (key.startsWith(data["1"])) {
                            let newKey = key.split("-");
                            if (newKey.length === 2) {
                                data[newKey[1]] = answer1[key];
                                added = true;
                            } else {
                                alert("ERROR");
                            }
                        }
                    }
                    if (!added) {
                        let item = {};
                        item[key] = answer1[key];
                        newData.push(item);
                    }
                }
            }
            //console.log(newData)

            var result = {
                groupA: JSON.stringify(newData),
                groupB: survey.data
            };
            //Write survey results into database
            window.sessionStorage.setItem("dataBackups", JSON.stringify(result));

            // 保存ファイル名をユーザが指定した場合の処理
            let filename = window.sessionStorage.getItem("filename") || null
            let dlFilename = survey.data["dl_filename"];
            if (dlFilename) {
                let dateObj = new Date();
                let date = dateObj.getFullYear() + //年の取得
                    ("00" + (dateObj.getMonth() + 1)).slice(-2) + //月の取得 ※0~11で取得になるため+1
                    ("00" + dateObj.getDate()).slice(-2) + //日付の取得
                    ("00" + dateObj.getHours()).slice(-2) + //時間の取得
                    ("00" + dateObj.getMinutes()).slice(-2) + //分の取得
                    ("00" + dateObj.getSeconds()).slice(-2); //秒の取得
                filename = dlFilename.trim() + "_" + date + ".json";
                window.sessionStorage.setItem("filename", filename)
            }

            if (!isElectron()){
                let json_data = window.sessionStorage.getItem("dataBackups") || null
                if (!json_data) return;
                let content = JSON.stringify(json_data).toString().replaceAll("\\\\\\\"","\"").replaceAll("\\\"","\"");
                content = content.replaceAll("\\\"{","{").replaceAll("}\\\"","}").replaceAll("\"{","{").replaceAll("}\"","}");
                content = content.replaceAll("\"[","[").replaceAll("]\"","]");

                let post_data = {
                    content: content,
                    filename: filename
                } 
                const requestOptions ={
                    method: "POST",
                    headers:{"Content-Type": "application/json"},
                    body: JSON.stringify(post_data)
                };
                fetch("/upload", requestOptions)
                .then(res =>{
                    if (!res.ok) {
                        throw new Error(res.statusText);
                    }
                    return res.text()
                })
                .then(text =>{
                    NotificationManager.success(text + "を保存しました", "問診データの保存");
                }).catch((error) => {
                    NotificationManager.error("サーバーとの通信に失敗しました", "Error", 5000, () => {
                        alert("callback");
                    });
                });
            }
        }
    }

    render() {
        //Create the model and pass it into react Survey component
        //You may create survey model outside the render function and use it in your App or component
        //The most model properties are reactive, on their change the component will change UI when needed.
        window.model = this.state.model
        window.model2 = new Survey.Model(this.json2);
        //質問文改行対応
        var converter = new showdown.Converter();
        converter.setFlavor("github");
        this.state.model.onTextMarkdown.add((model, options) => {
            //convert the markdown text to html
            var str = converter.makeHtml(options.text);
            //remove paragraphs <ol></<ol>
            if (str.startsWith("<ol>")) {
                str = str.replace("<ol>", "1.").replace("</ol>", "");
                str = str.replace("<li>", "").replace("</li>", "");
            }
            if (str.startsWith("<ol")) {
                str = str.replace("<ol start=\"", "");
                str = str.replace("\">", ". ");
                str = str.replace("</ol>", "");
                str = str.replace("<li>", "").replace("</li>", "");
            }
            //remove paragraphs <p></p>
            if (str.startsWith("<p>")) {
                str = str.replace("<p>", "").replace("</p>", "");
            }
            //set html
            options.html = str;
        })
        return (
            <div>
              <Survey.Survey model={this.state.model}
                onComplete={this.onComplete}
                onAfterRenderSurvey={this.onAfterRenderSurvey}
                onAfterRenderPage={this.onAfterRenderPage}
                onCurrentPageChanged={this.onCurrentPageChanged}
                onValueChanged={this.onValueChanged}
                onStarted={this.onStarted} />
              <NotificationContainer/>
            </div>
        );
    }
}
