export var bodyPartDic = {
  "頭部前面": {
    "ja": "頭部前面",
    "en": "Cranial region anterior aspect",
    "th": "ลักษณะบริเวณกะโหลกด้านหน้า"
  },
  "頭部後面": {
    "ja": "頭部後面",
    "en": "Cranial region posterior aspect",
    "th": "ลักษณะบริเวณกะโหลกด้านหลัง"
  },
  "項部": {
    "ja": "項部",
    "en": "Nuchal region",
    "th": "บริเวณหลังคอ"
  },
  "左肩部後面": {
    "ja": "左肩部後面",
    "en": "Left shoulder posterior aspect",
    "th": "ลักษณะไหล่ซ้ายด้านหลัง"
  },
  "右肩部後面": {
    "ja": "右肩部後面",
    "en": "Right shoulder posterior aspect",
    "th": "ลักษณะไหล่ขวาด้านหลัง"
  },
  "右三角筋部": {
    "ja": "右三角筋部",
    "en": "Right deltoid",
    "th": "กล้ามเนื้อเดลทอยด์ด้านขวา"
  },
  "左三角筋部": {
    "ja": "左三角筋部",
    "en": "Left deltoid",
    "th": "กล้ามเนื้อเดลทอยด์ด้านซ้าย"
  },
  "右肩甲部": {
    "ja": "右肩甲部",
    "en": "Right scapular region",
    "th": "บริเวณกระดูกสะบักด้านขวา"
  },
  "左肩甲部": {
    "ja": "左肩甲部",
    "en": "Left scapular region",
    "th": "บริเวณกระดูกสะบักด้านซ้าย"
  },
  "右肩甲下部": {
    "ja": "右肩甲下部",
    "en": "Lower right scapula",
    "th": "กระดูกสะบักด้านขวาช่วงล่าง"
  },
  "左肩甲下部": {
    "ja": "左肩甲下部",
    "en": "Lower left scapula",
    "th": "กระดูกสะบักด้านซ้ายช่วงล่าง"
  },
  "右腰部": {
    "ja": "右腰部",
    "en": "Right lumbar region",
    "th": "บริเวณเอวด้านขวา"
  },
  "左腰部": {
    "ja": "左腰部",
    "en": "Left lumbar region",
    "th": "บริเวณเอวด้านซ้าย"
  },
  "腰椎": {
    "ja": "腰椎",
    "en": "lumbar spine",
    "th": "กระดูกสันหลังส่วนเอว"
  },
  "下部胸椎": {
    "ja": "下部胸椎",
    "en": "lower thoracic spine",
    "th": "กระดูกสันหลังส่วนอกช่วงล่าง"
  },
  "上部胸椎": {
    "ja": "上部胸椎",
    "en": "upper thoracic spine",
    "th": "กระดูกสันหลังส่วนอกช่วงบน"
  },
  "右臀部": {
    "ja": "右臀部",
    "en": "Right buttock",
    "th": "แก้มก้นด้านขวา"
  },
  "左臀部": {
    "ja": "左臀部",
    "en": "Left  buttock",
    "th": "แก้มก้นด้านซ้าย"
  },
  "左前腕後部": {
    "ja": "左前腕後部",
    "en": "Left forearm posterior",
    "th": "แขนซ้ายช่วงล่างด้านหลัง"
  },
  "右前腕後部": {
    "ja": "右前腕後部",
    "en": "Right forearm posterior",
    "th": "แขนขวาช่วงล่างด้านหลัง"
  },
  "左大腿部": {
    "ja": "左大腿部",
    "en": "Left femoral region",
    "th": "บริเวณต้นขาซ้าย"
  },
  "右大腿部": {
    "ja": "右大腿部",
    "en": "Right femoral region",
    "th": "บริเวณต้นขาขวา"
  },
  "右膝窩部": {
    "ja": "右膝窩部",
    "en": "Right popliteal fossa",
    "th": "แอ่งขาพับด้านขวา"
  },
  "左膝窩部": {
    "ja": "左膝窩部",
    "en": "Left popliteal fossa",
    "th": "แอ่งขาพับด้านซ้าย"
  },
  "左下腿後部": {
    "ja": "左下腿後部",
    "en": "Left lower leg posterior",
    "th": "ขาซ้ายช่วงล่างด้านหลัง"
  },
  "右足底": {
    "ja": "右足底",
    "en": "Right plantar aspect",
    "th": "ลักษณะฝ่าเท้าขวา"
  },
  "右下腿後部": {
    "ja": "右下腿後部",
    "en": "Right lower leg posterior",
    "th": "ขาขวาช่วงล่างด้านหลัง"
  },
  "左足底": {
    "ja": "左足底",
    "en": "Left plantar aspect",
    "th": "ลักษณะฝ่าเท้าซ้าย"
  },
  "左側頸部": {
    "ja": "左側頸部",
    "en": "Left side neck",
    "th": "คอด้านซ้าย"
  },
  "前頸部": {
    "ja": "前頸部",
    "en": "Anterior neck",
    "th": "คอด้านหน้า"
  },
  "右側頸部": {
    "ja": "右側頸部",
    "en": "Right side neck",
    "th": "คอด้านขวา"
  },
  "左肩部前面": {
    "ja": "左肩部前面",
    "en": "Left shoulder anterior aspect",
    "th": "ลักษณะไหล่ซ้ายด้านหน้า"
  },
  "左胸筋部": {
    "ja": "左胸筋部",
    "en": "Left pectoralis muscle area",
    "th": "บริเวณกล้ามเนื้ออกด้านซ้าย"
  },
  "右胸筋部": {
    "ja": "右胸筋部",
    "en": "Right pectoralis muscle area",
    "th": "บริเวณกล้ามเนื้ออกด้านขวา"
  },
  "右肩部前面": {
    "ja": "右肩部前面",
    "en": "Right shoulder anterior aspect",
    "th": "ลักษณะไหล่ขวาด้านหน้า"
  },
  "胸骨部": {
    "ja": "胸骨部",
    "en": "Sternum",
    "th": "กระดูกสันอก"
  },
  "左上腕前部": {
    "ja": "左上腕前部",
    "en": "Left upper arm anterior",
    "th": "แขนซ้ายช่วงบนด้านหน้า"
  },
  "左下肋部": {
    "ja": "左下肋部",
    "en": "Left lower ribcage",
    "th": "โครงกระดูกช่วงล่างด้านซ้าย"
  },
  "上腹部": {
    "ja": "上腹部",
    "en": "Upper abdomen",
    "th": "ท้องส่วนบน"
  },
  "右上腕前部": {
    "ja": "右上腕前部",
    "en": "Right upper arm anterior",
    "th": "แขนขวาช่วงบนด้านหน้า"
  },
  "右下肋部": {
    "ja": "右下肋部",
    "en": "Right lower ribcage",
    "th": "โครงกระดูกช่วงล่างด้านขวา"
  },
  "右肘窩部": {
    "ja": "右肘窩部",
    "en": "Right cubital fossa",
    "th": "แอ่งแขนพับด้านขวา"
  },
  "左肘窩部": {
    "ja": "左肘窩部",
    "en": "Left cubital fossa",
    "th": "แอ่งแขนพับด้านซ้าย"
  },
  "左側腹部": {
    "ja": "左側腹部",
    "en": "Left side abdomen",
    "th": "ท้องด้านซ้าย"
  },
  "右側腹部": {
    "ja": "右側腹部",
    "en": "Right side abdomen",
    "th": "ท้องด้านขวา"
  },
  "臍部": {
    "ja": "臍部",
    "en": "Umbilical region",
    "th": "บริเวณสะดือ"
  },
  "右前腕前部": {
    "ja": "右前腕前部",
    "en": "Right forearm anterior",
    "th": "แขนขวาช่วงล่างด้านหน้า"
  },
  "左前腕部": {
    "ja": "左前腕部",
    "en": "Left forearm region",
    "th": "บริเวณแขนซ้ายช่วงล่าง"
  },
  "恥骨部": {
    "ja": "恥骨部",
    "en": "Pubic bone region",
    "th": "บริเวณกระดูกหัวหน่าว"
  },
  "左手掌": {
    "ja": "左手掌",
    "en": "Left palm",
    "th": "ฝ่ามือซ้าย"
  },
  "右手掌": {
    "ja": "右手掌",
    "en": "Right palm",
    "th": "ฝ่ามือขวา"
  },
  "右大腿三角部": {
    "ja": "右大腿三角部",
    "en": "Right femoral triangle",
    "th": "สามเหลี่ยมต้นขาขวา"
  },
  "左大腿三角部": {
    "ja": "左大腿三角部",
    "en": "Left femoral triangle",
    "th": "สามเหลี่ยมต้นขาซ้าย"
  },
  "左大腿前部": {
    "ja": "左大腿前部",
    "en": "Left thigh anterior",
    "th": "ต้นขาซ้ายด้านหน้า"
  },
  "右大腿前部": {
    "ja": "右大腿前部",
    "en": "Right thigh anterior",
    "th": "ต้นขาขวาด้านหน้า"
  },
  "左膝蓋部": {
    "ja": "左膝蓋部",
    "en": "Left patella",
    "th": "กระดูกสะบ้าด้านซ้าย"
  },
  "右膝蓋部": {
    "ja": "右膝蓋部",
    "en": "Right patella",
    "th": "กระดูกสะบ้าด้านขวา"
  },
  "右下腿前部": {
    "ja": "右下腿前部",
    "en": "Right lower leg anterior",
    "th": "ขาขวาช่วงล่างด้านหน้า"
  },
  "左下腿前部": {
    "ja": "左下腿前部",
    "en": "Left lower leg anterior",
    "th": "ขาซ้ายช่วงล่างด้านหน้า"
  },
  "右足背": {
    "ja": "右足背",
    "en": "Right foot dorsal",
    "th": "หลังเท้าขวา"
  },
  "左足背": {
    "ja": "左足背",
    "en": "Left foot dorsal",
    "th": "หลังเท้าซ้าย"
  }
}