import React from "react";
import * as Survey from "survey-react";
import {ReactComponent as Logo} from "./body.svg";
import { bodyPartDic }  from "./bodypart";

export class BodyQuestionMultipleModel extends Survey.Question {
    getType() {
        return "bodyquestion_multiple";
    }
    get maxSelectedChoices() {
        return this.getPropertyValue("maxSelectedChoices", -1);
    }
    set maxSelectedChoices(newValue) {
        this.setPropertyValue("maxSelectedChoices", newValue);
    }
    get value() {
        return this.getPropertyValue("value", "");
    }
    set value(newValue) {
        this.setPropertyValue("value", newValue);
    }
}

export class BodyQuestion extends Survey.SurveyElementBase {
    
    


    constructor(props) {
        super(props);

        // thisを参照できるようにする。
        this.handleClick = this.handleClick.bind(this);
    }

    get question() {
        return this.props.question;
    }

    handleClick(e) {
        // 人体図のクリックイベント
        let id = e.target.getAttribute("id");
        let convId = unicodeUnescape(id);

        if (/[a-zA-Z0-9]/i.test(convId)) {
            return;
        }

        let target = document.getElementById(id);
        let selected = this.question.value.length ? new Set(this.question.value.split(",")) : new Set();

        if (selected.has(convId)) {
            // すでに値を持っている場合は選択解除処理
            selected.delete(convId);
            target.style.fill = "#808080";
            target.style.fillOpacity = "0.0164319";

        } else {
            // 選択処理
            let limit = this.question.maxSelectedChoices;
            // 最大選択数を超えてクリックさせない。
            if (limit < 0 || selected.size < limit) {
                selected.add(convId);
                target.style.fill = "#FFE869";
                target.style.fillOpacity = "unset";
            }
        }

        this.question.value = Array.from(selected).join(",");
        this.question.survey.setValue(this.question.name, this.question.value);

        let valuelist = this.question.value.split(",") || [];
        let defaultText = "";
        for (let i = 0; i < valuelist.length; i++) {
            let lang = this.question.survey.data["language"];
            let partName = bodyPartDic[valuelist[i]] || {};
            if (defaultText.length === 0) {
                defaultText = partName[lang] || partName["en"] || "";
            } else {
                let selectedName = partName[lang] || partName["en"] || "";
                defaultText += ", " + selectedName;
            }
        }
        document.getElementById("bodyQuestionValue").value = defaultText;
    }

    componentDidMount() {
        // 初期表示（画面が戻ったときに選択肢を再描画）
        let selected = this.question.value.length ? new Set(this.question.value.split(",")) : new Set();
        selected.forEach((value) => {
            let target = document.getElementById(value);
            if (!target) {
                target = document.getElementById(unicodeEscape(value));
            }
            if (target) {
                target.style.fill = "#FFE869";
                target.style.fillOpacity = "unset";
            }
        });
    }

    // TODO サイズ指定は別途決める。
    render() {
        if (!this.question) return null;
        var cssClasses = this.question.cssClasses;
        let defaultValue = this.question.value || "";
        let valuelist = this.question.value.split(",")
        let defaultText = "";
        for (let i = 0; i < valuelist.length; i++) {
            let lang = this.question.survey.data["language"];
            let partName = bodyPartDic[valuelist[i]] || "";
            if (defaultText.length === 0) {
                defaultText = partName[lang] || partName["en"];
            } else {
                let selectedName = partName[lang] || partName["en"];
                defaultText += ", " + selectedName;
            }
        }

        return (
            <div style={{textAlign: "center"}} className={cssClasses.root}>
                <Logo width="40%" height="40%" onClick={this.handleClick} />
                <input type="text" id="bodyQuestionValue" disabled="disabled" value={defaultText} />
            </div>
        );
    }
}

Survey.Serializer.addClass(
    "bodyquestion_multiple",
    [
        { name: "maxSelectedChoices"}
    ],
    function() {
        return new BodyQuestionMultipleModel("");
    },
    "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("bodyquestion_multiple", props => {
    return React.createElement(BodyQuestion, props);
});

var unicodeUnescape = function(str) {
    var result = "";
    if (str !== null) {
        var strs = str.match(/\\u.{4}/ig);
        if (!strs) return str;
        for (var i = 0, len = strs.length; i < len; i++) {
            result += String.fromCharCode(strs[i].replace('\\u', '0x'));
        }
    }
    return result;
};

var unicodeEscape = function(str) {
    if (!String.prototype.repeat) {
        String.prototype.repeat = function(digit) {
            var result = "";
            for (var i = 0; i < Number(digit); i++) result += str;
            return result;
        };
    }

    var strs = str.split(''), hex, result = "";

    for (var i = 0, len = strs.length; i < len; i++) {
        hex = strs[i].charCodeAt(0).toString(16).toUpperCase();
        result += "\\u" + ("0".repeat(Math.abs(hex.length - 4))) + hex;
    }

    return result;
};

