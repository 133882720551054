import * as Survey from "survey-react";
import Dropzone from "react-dropzone";
// eslint-disable-next-line
import React, { useCallback } from "react";

export class JsonImportQuestionModel extends Survey.Question {
    getType() {
        return "jsonimportquestion";
    }
}

export class JsonImportQuestion extends Survey.SurveyElementBase {
    constructor(props) {
        super(props);
        this.state = {
            filename: ""
        };
        // thisを参照できるようにする。
        this.onDrop = this.onDrop.bind(this);
    }

    get question() {
        return this.props.question;
    }

    componentDidMount() {
        // Get URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const folder_name = urlParams.get("folder_name");
        const subfolder_name = urlParams.get("subfolder_name");
        const filename = urlParams.get("filename");
        const _ = urlParams.get("_");
        const modeType = folder_name.split("_")[1]

        if (folder_name && subfolder_name && filename) {
            const url = `https://recognition.physical-care-digital.com:3002/spine-analysis-demo/web/movie/${folder_name}/${subfolder_name}/${filename}`;
            const key = `state-${modeType}`;
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    this.setState({ filename: filename });
                    window.sessionStorage.setItem(
                        "importedValue",
                        JSON.stringify({[key]: data.state})
                    );
                })
                .catch((error) => {
                    console.error("Error fetching JSON file:", error);
                });
        }
    }

    onDrop(files) {
        this.setState({filename: "ファイル名 : " + files[0].name});
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted")
            reader.onerror = () => console.log("file reading has failed")
            reader.onload = () => {
                let jsonStr = reader.result
//                 let jsonData = JSON.parse(jsonStr);
                window.sessionStorage.setItem("importedValue", jsonStr);
            }
            reader.readAsText(file);
        });
    }

    render() {
        return (
            <Dropzone onDrop={this.onDrop}>
            {({getRootProps, getInputProps}) => (
                <section>
                    <div {...getRootProps({className: 'dndzone'})}>
                        <input {...getInputProps()} />
                        <p>{this.question.title}</p>
                        <p>{this.state.filename}&nbsp;</p>
                    </div>
                </section>
            )}
            </Dropzone>
        );
    }
}

Survey.Serializer.addClass(
    "jsonimportquestion",
    [
    ],
    function() {
        return new JsonImportQuestionModel("");
    },
    "question"
);
Survey.ReactQuestionFactory.Instance.registerQuestion("jsonimportquestion", props => {
  return React.createElement(JsonImportQuestion, props);
});
